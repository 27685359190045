const devTerm = [
  {
    title: '서버(Server) vs. 클라이언트(Client)',
    content:
      '서비스를 제공하느냐/제공받느냐에 따른 구분. 외부에 필요한 서비스를 제공하도록 만든 컴퓨터나 프로그램 영역을 서버라고 한다면, 여기에 접속해 서비스를 요청하고 제공받는 유저가 클라이언트가 된다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '프론트엔드 개발자(Front-end Developer)',
    content:
      '웹 브라우저를 통해 유저가 직접 마주하는 웹 서비스의 앞단(front-end)을 담당하는 개발자. 클라이언트/서버를 기준으로 보면 웹 페이지 화면을 비롯한 클라이언트 영역을 프론트엔드라고 할 수 있다. ',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '백엔드 개발자(Back-end Developer)',
    content:
      '프론트엔드 개발자의 반대 개념으로, 웹 서비스의 뒷단(Back-end)을 담당하는 개발자. 주로 유저에게 보이지 않는 DB와 API로 이루어진 서버 영역을 관리, 개발하는 역할을 한다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '풀스택(Full-Stack)',
    content:
      '프론트엔드 개발과 백엔드 개발 영역을 통틀어 이르는 말, 혹은 모두 다룰 수 있는 개발자. 희귀종, 유니콘, 일당백이 될 수도 있지만 쏟아지는 업무에 시달리거나 이도저도 아닌 빈 스택(Empty Stack)이 될 수도 있으니 너무 막연한 환상은 금물.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '프레임워크(Framework)',
    content:
      '프로그램을 개발하기 위한 구조를 제공하는 개발 환경. 코딩을 할 때 자주 쓰이는 여러 클래스(Class, 변수와 함수의 묶음)를 프레임워크가 정해둔 흐름에 맞춰 쓸 수 있도록 지원해준다.',
    script:
      '(자바스크립트에서는 뷰(Vue.js)와 앵귤러(Angular)가, 자바에서는 스프링(Spring)이 대표적인 웹 프레임워크로 꼽힌다.)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '라이브러리(Library)',
    content:
      '프로그램을 개발하는 데 필요한 여러 기능을 활용할 수 있도록 묶어놓은 함수 또는 기능의 집합. 프레임워크가 개발을 제어하는 틀거리를 제공해준다면, 라이브러리는 도서관에서 책을 빌리듯 개발자가 필요한 기능을 마음대로 가져다 쓸 수 있는 일종의 모음집에 가깝다.',
    script: '(자바스크립트 웹 개발에 쓰이는 리액트(React)와 제이쿼리(jQuery)가 바로 라이브러리다.)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '플러그인(Plug-in)',
    content:
      '어떤 특정한 기능을 해결하는 데 쓸 수 있도록 미리 만들어 놓은 코드 및 데이터의 모음. 한 라이브러리나 프레임워크에서 여러 개의 플러그인을 제공한다고 볼 수 있다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '웹 퍼블리셔(Web Publisher)',
    content:
      '디자인 시안에 맞춰 웹 화면을 구현하는 웹 퍼블리싱(Web Publishing)을 담당하는 직무로 한국에서만 쓰이는 말이다. 디자인은 물론 웹 표준과 호환성을 고려해 코딩을 진행하고, 디자이너와 프론트엔드 개발자 사이에서 의견을 조율하기도 한다. ',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '마크업 언어(Markup Language)',
    content:
      '태그 등을 이용해 문서나 데이터의 구조를 표시한 언어. 프로그래밍 언어와는 다르며, HTML, CSS, XML이 대표적인 마크업 언어다',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '검색 엔진 최적화(Search Engine Optimize)',
    content:
      '줄여서 SEO. 웹 페이지를 구글 등 검색 엔진이나 포털사이트의 기준에 맞춰 구성하는 작업. 사람들이 많이 검색할 만한 관련 키워드를 배치하고, 웹 표준을 지키며, 스팸으로 분류되지 않게끔 하는 등 웹 페이지 링크가 더 많이 노출되도록 처리한다.',
    script: '온라인 마케팅의 필수 요소로도 꼽히는데, SEO가 잘 적용된 웹 페이지는 검색 시 상단에 오를 수 있기 때문.',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '버그(Bug)',
    content: '소프트웨어에서 발생하는 예견치 못한 오류나 오작동. 있어도 이상하고 없어도 이상한 것.',
    script: '(새로 배포한 기능에서 버그가 발견되지 않으면 오히려 초조해하는 개발자들의 모습을 볼 수 있다...)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '에러(Error)',
    content:
      '유저가 입력한 내용이 잘못됐을 때 발생하는 문제. 잘못 짠 코드처럼 내부 문제로 예상하지 못한 문제가 일어나는 버그와 달리, 외부에 있는 유저가 오탈자를 내거나 띄어쓰기를 실수하는 등 내부에서 정한 형식을 따르지 않을 때 주로 발생한다.',
    script: '(예를 들어 숫자만 적도록 되어있는 주민번호 입력창에 stirng 형식을 적는다면 에러가 일어날 수 있다.)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '예외(Exception)',
    content:
      '에러가 일어날 가능성을 개발자가 미리 예상하고 프로그램이 비정상적으로 종료되지 않도록 하는 것. 예외 처리(Exception Handling) 또는 트러블 슈팅(Trouble Shooting)을 통해 예외를 구분하고, 유저에게 문제를 바로잡아달라는 에러 메시지를 띄워줘야 에러로 인한 문제를 예방할 수 있다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '트래픽(Traffic)',
    content:
      '서버를 통해 전송되는 데이터의 양. 서비스 방문자 수가 많을수록, 접속이 잦아질수록 증가한다. 트래픽이 너무 높아지면 서버가 먹통이 된다. ',
    script:
      '(흔히 대학교 수강신청 날 학생들이 많이 몰려 수강신청 사이트가 ‘터져’ 버리거나, 외부에서 많은 PC를 감염시켜 의도적으로 특정 서비스가 마비되도록 공격하는 것(DDoS, 분산 서비스 거부 공격) 모두 트래픽 초과와 관련이 깊다.)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '데이터베이스(Database)',
    content: '줄여서 DB. 데이터의 집합. 서버를 통해 넘어오는 데이터를 저장하고 관리하는 일종의 창고 역할을 한다. ',
    script:
      '(데이터베이스에 과부하가 걸리면 서버에도 지장을 주고, 관리가 잘못되면 데이터가 날아가는 아찔한 상황이 벌어질 수도 있으니 주의, 또 주의!)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '데이터베이스 관리 시스템(Database Management System)',
    content: '말 그대로 데이터베이스를 통해 데이터를 저장, 수정, 추출할 수 있도록 관리하는 프로그램. 줄여서 DBMS.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '관계형 데이터베이스(Relational DataBase)',
    content:
      '데이터베이스에서 키(key)과 값(value)의 관계(Relationship)로 이루어진 표(table)로 데이터를 구성하는 방식으로, 줄여서 RDB. 오라클(Oracle), MySQL, Microsoft SQL Server 등 가장 널리 쓰이는 데이터베이스 관리 시스템이 관계형 데이터베이스 모델을 따르고 있다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '쿼리(Query)',
    content:
      '관계형 데이터베이스에서 데이터를 요청하고 관리하기 위해서는 SQL(Structured Query Language, 구조화 쿼리 언어)이라는 데이터베이스 언어를 써야 한다.',
    script:
      'SQL을 이용하면 데이터베이스에 쿼리(Query), 즉 질의를 통해 원하는 조건에 맞는 데이터를 추출하거나 조작할 수 있다.',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: 'API(Application Programming Interface)',
    content:
      '응용 프로그램 인터페이스. 어떤 응용 프로그램에서 특정한 기능을 사용하기 위해 필요한 데이터를 주고받게끔 만든 도구나 방법을 뜻한다. API가 규격에 맞게 데이터를 요청하고 받아볼 수 있도록 하는 중간 창구 역할을 하는 셈이다.',
    script:
      '(여러 웹사이트에서 페이스북이나 카카오톡 계정을 통한 간편 로그인을 제공하는 것 역시 API를 통해 이루어진다.)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '오픈소스(Open Source)',
    content:
      '누구나 제한 없이 쓸 수 있는 소스 코드 혹은 소프트웨어. 오픈 소스로 배포된 코드는 열람, 수정, 복제, 재배포 등이 자유롭다. 한국에서는 공공데이터포털을 통해 공개된 오픈 API를 내려받을 수 있다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: 'SDK(Software Development Kit)',
    content:
      '소프트웨어나 시스템을 만드는 데 쓰이는 개발 도구 키트. SDK 안에는 개발에 필요한 샘플 코드, 코드 편집기 같은 툴이나 콘솔, 안내 문서, API 등이 포함된다. ',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: 'IDE(Integrated Development Environment)',
    content:
      '프로그램을 개발하는 데 필요한 소스 코드 작성 및 편집, 컴파일, 디버깅 등 모든 작업을 한번에 할 수 있는 통합 개발 환경. ',
    script: '(비주얼 스튜디오(Visual Studio), 이클립스(Eclipse), 인텔리제이 아이디어(IntelliJ Idea) 등.)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '유닉스(Unix) ',
    content:
      '기계어에 가까운 언어인 어셈블리어로 개발한 컴퓨터 운영 체제. 주로 서버 개발이나 시스템 개발에 쓰이며, macOS나 리눅스(Linux), 안드로이드(Android) 등 오늘날 많은 운영 체제의 원형이 되었다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '리눅스(Linux)',
    content:
      '유닉스 계열에 속하는 오픈소스 운영체제. 서버 개발 및 구축, 수퍼컴퓨터, 임베디드 기기, 스마트폰 OS 개발 등 다양한 용도로 활발하게 사용되고 있다.',
    script: '(우분투(Ubuntu)와 데비안(Devian)이 대표적.)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '스레드(Thread, 쓰레드)',
    content:
      '프로그램을 실행하는 하나의 프로세스(Process) 내에서 실제로 작업을 처리하는 주체를 뜻한다. 모든 프로세스는 반드시 한 개 이상의 스레드를 가진다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '도메인(Domain)',
    content: '숫자로만 이루어진 IP와 달리 inflearn.com처럼 문자로 지정된 인터넷 주소(URL).',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '프로토콜(Protocol)',
    content: '컴퓨터나 서버, 통신 장비 등 장치 사이에서 서로 통신하기 위해 미리 정해놓은 규칙.',
    script: 'TCP/IP, HTTPS, HTTP 등이 모두 인터넷 프로토콜이다.)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: 'HTTP, HTTPS',
    content:
      'HTTP는 하이퍼텍스트 전송 프로토콜(Hypertext Transfer Protocol)의 약자로 네트워크에서 HTML 문서를 주고받기 위한 통신규약이다. 한편, HTTPS는 HTTP가 가진 보안 취약점을 암호화 및 인증 구조를 통해 개선하기 위해 만들어졌다.',
    script: '(보안 소켓 레이어(SSL, Secure Socket Layer) 개념을 덧붙인 것.)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '동기 방식(Sync.) vs. 비동기 방식(Async.)',
    content:
      '동기 방식(Synchronous)은 기능에 대한 요청을 보내고 응답을 받아야만 동작이 순차적으로 처리되는 방식이며, 반대로 비동기 방식(Asynchronous)은 요청을 보내는 순서와 상관 없이 동작이 처리되는 방식이다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (1)',
  },
  {
    title: '정적 웹 페이지(Static Web Page)',
    content:
      '서버에 미리 저장된 데이터를 그대로 불러오듯 전달하는 웹 페이지. 서버의 데이터가 바뀌지 않는 한 모든 사용자는 늘 고정된 웹페이지를 받아보게 된다. ',
    script: '주로 서비스 소개페이지처럼 내용이 자주 변경되지 않는 경우 정적 웹 페이지를 이용하는 경우가 많다.',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '동적 웹 페이지(Dynamic Web Page)',
    content:
      '정적 웹 페이지와 반대로, 서버에 저장된 데이터를 그대로 보여주는 것이 아니라 스크립트를 통해 가공을 거쳐 출력하는 웹페이지.',
    script: '매번 새롭게 정보가 갱신되는 게시판, SNS처럼 결과가 그때그때 바뀐다면? 전부 동적 웹!)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '적응형 웹(Adaptive Web Design)',
    content:
      '미리 웹 브라우저가 동작할 기기(스마트폰, PC, 태블릿...) 별로 레이아웃을 여러 개 정해놓고 조건에 따라 그 중 하나를 보여주는 웹 디자인 방식.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '반응형 웹(Responsive Web Design)',
    content:
      '적응형 웹과 달리 하나의 레이아웃이 웹 브라우저에 맞춰지는 형태로, 웹 브라우저의 가로폭이 바뀔 때마다 페이지 내에 있는 콘텐츠의 크기와 배치도 자동으로 맞춰진다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '인터랙티브 웹(Interactive Web)',
    content: '웹 페이지 내의 콘텐츠가 클릭, 스크롤, 입력 등 사용자의 동작에 따라 상호작용하는(interact) 웹 페이지.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '캐시(Cache)',
    content:
      '데이터베이스, 서버, 세션 관리 등 다양한 장치에서 널리 쓰이는 개념으로, 사용자가 데이터에 빠르게 접근할 수 있도록 데이터를 임시로 저장해둔 장소. ',
    script: '이를 활용하는 것을 캐싱(Caching)이라고 한다.',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '브라우저 캐싱(Browser Caching)',
    content:
      '웹 페이지에 방문했을 때 페이지를 이루는 이미지나 HTML, CSS 문서 등 일부 데이터를 사용자의 PC에 저장해둔 뒤, 페이지를 재방문했을 때 서버에 요청할 필요 없이 PC에 저장했던 데이터를 불러오는 캐싱 기술.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '세션(Session)',
    content:
      '웹 브라우저를 통해 서버에 접속해 연결을 끊기 전까지(=브라우저를 종료하기 전까지) 한 브라우저를 통해 사용자가 요청하는 상태 정보를 서버에서 일정하게 유지하는 기술. ',
    script: '한 사이트 내에서 페이지를 이동할 때마다 로그인이 끊기지 않도록 하는 게 대표적인 예다.',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '쿠키(Cookie)',
    content:
      '서버에 저장되는 세션과는 달리 사용자의 브라우저에 간단한 정보를 저장하는 작은 데이터 파일로, 키(Key)와 값(Value)의 구조로 이루어진다. ',
    script: '브라우저에 ID나 암호를 저장하거나, 사이트 방문 기록을 저장하는 데 주로 쓰인다.',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: 'UX(User eXperience)',
    content: '사용자가 서비스를 이용하며 느끼고 얻는 경험.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: 'UI(User Interface)',
    content: '사용자가 서비스를 이용하는 환경. UI는 사용자 경험(UX)에 큰 영향을 미친다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: 'GUI(Graphic User Interface)',
    content:
      '그래픽 유저 인터페이스. 사용자가 화면 안의 요소를 시각적으로 확인하고, 마우스로 아이콘을 클릭해 기능을 작동시키는 환경.',
    script: '도스처럼 문자로 된 명령어를 입력시켜야 하는 CLI(Command-Line Interface)에 반대되는 개념이다.',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '모달(Modal Window)',
    content:
      'UI 디자인 요소로, 웹페이지나 프로그램 화면에서 대화상자 형태로 나타나 사용자에게 동작을 요구하는 창. 웹에서는 팝업(Pop-up) 창과 비슷하지만, 브라우저를 새로 띄우는 팝업과 달리 한 브라우저 화면 내부에서 레이어를 한 겹 얹듯이 새로운 창을 보여준다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '임베디드(Embedded, Embedded System)',
    content:
      '특정 기능을 수행하는 전자 시스템을 내장해놓은 것. 일반적인 PC가 다양한 목적에 따라 범용적으로 쓰인다면, 임베디드 시스템은 각종 가전제품, ATM, 개표기, 키오스크 등 특수한 목적에 따라 기능을 수행하도록 제작된다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '펌웨어(Firmware)',
    content:
      '하드웨어를 제어하는 소프트웨어. 하드웨어가 출고될 때부터 포함되어 있는(임베디드) 프로그램인 만큼 하드웨어보다는 교체하기 쉽지만 일반적인 소프트웨어에 비해서는 어렵기 때문에 soft도 hard도 아닌 그 중간쯤의 firm이라는 이름이 붙었다.',
    script: '인터넷을 쓰게 해주는 공유기에도 펌웨어가 깔려있다.',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '알고리즘(Algorithm)',
    content:
      '문제를 해결하기 위해 필요한 계산 절차. 프로그래밍 언어를 통해 알고리즘을 프로그램으로 만들어가는 작업을 프로그래밍이라고 한다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '자료 구조(Data Structure)',
    content:
      '데이터를 효과적으로 접근할 수 있도록 만들어진 데이터 체제로, 코드상에서 자료를 저장하는 방법, 자료끼리의 관계 등을 구조적으로 표현하는 방식. 어떤 자료구조를 쓰느냐에 따라 코드 효율이 달라진다.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '디버깅(Debugging)',
    content:
      '디버깅 또는 디버그(Debug). 프로그래밍 과정에서 발생하는 버그를 찾아 바로잡는 작업. 단순히 버그를 없애는 것뿐만 아니라 문제가 발생한 근본적인 원인을 찾아 해결하는 과정이다.',
    script: '(코드 짜는 시간보다 디버깅이 훨씬 오래 걸린다면? 삑, 정상입니다.)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '리팩토링(Refactoring)',
    content:
      '이미 완성된 코드의 구조를 개선하는 일. 결과값은 바뀌지 않지만, 코드 내부를 뜯어고쳐 다른 사람이 이해하기 쉽고 간결하게 만드는 행위. ',
    script: '버그를 고치거나 새로운 기능을 만드는 건 아니지만, 코드의 생산성을 높이고 유지보수가 쉽게끔 만들어준다.',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '코딩 컨벤션(Coding Convention)',
    content:
      '여러 개발자가 협업하는 과정에서 서로의 코드를 파악하고 관리하기 쉽도록 일관적인 스타일을 유지하게끔 하는 코드 작성 규칙. 띄어쓰기나 들여쓰기, 함수나 변수의 이름을 지정해주는 방법 등 정해진 코딩 컨벤션을 준수해야 한 프로젝트 안에서 통일된 코드를 짤 수 있다. ',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '기술 부채(Technical Debt)',
    content: '불확실한 업무, 촉박한 일정에 맞춰 제한된 방법으로 개발을 진행하면서 문제점이 누적된다는 관점.',
    script:
      '조악한 코드로 작업을 계속하게 되거나, 자동화할 수 있는 일에 대한 자동화 처리를 해놓지 않는 등 다양한 상황에서 기술 부채가 쌓일 수 있다.',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '컴파일(Compile), 컴파일러(Compiler), 컴파일드 언어(Compiled Language)',
    content:
      '사람이 이해할 수 있는 고급 프로그래밍 언어로 작성한 코드를 컴퓨터가 처리할 수 있는 기계어로 한꺼번에 번역하는 과정을 컴파일(Compile)이라고 하는데, 이때 쓰이는 프로그램 또는 시스템이 컴파일러다. 수정이 까다롭지만 프로그램 실행 속도가 빠르다는 장점이 있다. C, C++이 대표적인 컴파일드 언어.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '인터프리트(Interpret), 인터프리터(Interpreter), 인터프리티드 언어(Interpreted Language)',
    content:
      '인터프리트(interpret)는 컴파일과 달리 프로그램을 실행할 때마다 고급 프로그래밍 언어로 작성된 코드를 한 줄씩 기계어로 번역하는 방식이다. 한 줄 한 줄씩 코드를 실행해내려가기 때문에 실행 속도는 느리지만 디버깅은 쉽다. Javascript, JAVA, Python, PHP 등이 인터프리티드 언어에 속한다. ',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '깃(Git) ',
    content:
      '깃(Git)은 프로젝트 결과물을 버전별로 백업, 관리하는 툴인 버전 관리 시스템(VCS, Version Control System)의 한 종류로, 프로젝트를 유지보수하면서 발생할 수 있는 문제를 미연에 방지하고 해결하는 역할을 한다.',
    script: '(실수로 잘못된 코드를 덮어쓴다거나, 다시 활용해야 하는 기존 코드를 날린다거나...)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '깃허브, 깃헙(Github)',
    content:
      '깃을 기반으로 소스 코드를 온라인에 저장하고, 공유할 수 있게끔 하는 소스코드 관리 서비스. 언제 어디서나 깃허브를 통해 협업 프로젝트를 쉽게 관리할 수 있다.',
    script:
      '뷰(Vue.js), 도커(Docker), 판다스(Pandas) 등 전세계의 다양한 오픈소스 프로젝트가 깃허브를 통해 공개되고 있다. 개발 직군에서 취업하거나 이직할 때 깃허브 계정 링크를 제출하는 경우도 상당수.',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '리포지터리, 레포지토리(Repository)',
    content:
      '말 그대로 저장소. 파일이나 폴더 등 각종 정보를 저장해두는 공간으로, 깃허브를 통해 개인 또는 팀을 위한 공개/비공개 리포지터리를 무료로 만들 수 있다. 리포, 레포(Repo)로 줄여 부르기도.',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '커밋(Commit)',
    content:
      '깃(Git)에서 코드에 대한 수정사항을 기록하는 명령. 게임을 플레이하다가 세이브 파일을 남기는 것처럼, 커밋을 생성하면 커밋한 시점의 작업 변경 이력을 저장할 수 있다. ',
    script:
      '(몇 년 전부터 개발자들 사이에서는 깃허브 등에 매일매일 자신이 코딩한 내용을 기록하고 공개하는 ‘일일커밋’이 유행하고 있다는 사실!)',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
  {
    title: '스택오버플로, 스택오버플로우(Stack Overflow)',
    content:
      '세계에서 가장 큰 개발자 웹 커뮤니티 중 하나로, 프로그래밍에 대한 다양한 질문/답변이 올라온다. 이름은 프로그래밍 과정에서 데이터가 할당된 메모리 공간을 초과할 때 발생하는 동명의 오류에서 따온 것. ',
    script: '',
    author: '인프런/뉴비를 위한 개발 용어 사전 (2)',
  },
];
export default devTerm;

// {
//     title: "",
//     content : "",
//     script : "",
//     author : "인프런/뉴비를 위한 개발 용어 사전 (2)",
// },
